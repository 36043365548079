<template>
  <div class="d-flex align-items-stretch flex-shrink-0">
    <template v-if="!$isMobile()">
      <div class="d-flex align-items-center ms-1 ms-lg-2">
        <div class="d-flex align-items-center" @click="toggleFullScreen">
          <div class="btn btn-icon p-2">
            <i
              class="fas fa-expand-arrows-alt text-black fa-2x"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              :title="
                $t(
                  'Components.Topbar.OpenFullScreenMode',
                  {},
                  { locale: this.$store.state.activeLang }
                )
              "
              v-if="!isFullScreenView"
            ></i>
            <i
              class="fas fa-compress-arrows-alt text-black fa-2x"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              :title="
                $t(
                  'Components.Topbar.ExitFullScreenMode',
                  {},
                  { locale: this.$store.state.activeLang }
                )
              "
              v-else
            ></i>
          </div>
        </div>
      </div>
      <div
        class="d-flex align-items-center ms-1 ms-lg-2"
        v-if="this.$store.getters._isDocumentActive"
      >
        <div
          @click="redirectDocumentPage"
          class="d-flex align-items-stretch h-100 cursor-pointer topbar-documents"
        >
          <div class="d-flex align-items-center">
            <i
              class="fa fa-folder-open text-black fa-2x"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              :title="
                $t(
                  'Components.Topbar.Documents',
                  {},
                  { locale: this.$store.state.activeLang }
                )
              "
            ></i>
          </div>
        </div>
      </div>
      <div
        class="d-flex align-items-stretch me-1 ms-lg-2 topbar-delegations"
        v-if="this.$store.getters._isDeletegationActive"
      >
        <Delegations :isDrawerSidePanel="true"></Delegations>
      </div>
      <div class="d-flex align-items-stretch me-1 ms-lg-2 topbar-search">
        <Search></Search>
      </div>
      <div
        class="d-flex align-items-center me-1 ms-lg-2 topbar-notifications"
        @click="redirectNotificationPage"
        v-if="this.$store.getters._isSystemNotificationActive"
      >
        <div class="d-flex align-items-stretch h-100">
          <div class="d-flex align-items-center position-relative">
            <i class="fa fa-bell text-black fa-2x"></i>
            <span
              class="badge badge-notify"
              :class="{
                'bg-success': $store.state.channelNotificationCount == 0,
                'bg-danger': $store.state.channelNotificationCount > 0,
              }"
            >
              {{ $store.state.channelNotificationCount }}
            </span>
          </div>
        </div>
      </div>
    </template>
    <div
      class="d-flex align-items-center ms-1 ms-lg-2"
      id="sx_header_user_menu_toggle"
    >
      <div
        class="d-none d-md-flex flex-column align-items-end justify-content-center me-2"
      >
        <span class="text-black fs-5 fw-bold lh-1">{{
          this.$store.getters.fullUserName
        }}</span>
      </div>
      <div
        class="cursor-pointer symbol symbol-40px"
        :data-sx-menu-trigger="$isMobile() ? 'click' : 'hover'"
        data-sx-menu-attach="parent"
        data-sx-menu-placement="bottom-end"
        data-sx-menu-flip="bottom"
        data-sx-menu-target="#sx-user-menu"
      >
        <img :src="require('@/assets/img/icons/user/user-icon-sm.png')" />
      </div>
      <UserMenu></UserMenu>
    </div>
    <div class="d-flex align-items-center d-lg-none ms-1">
      <div class="btn btn-icon btn-sm" id="sx_header_menu_mobile_toggle">
        <i class="bi bi-box-arrow-left not-white text-black fs-28"></i>
      </div>
    </div>
  </div>
</template>

<script>
import Search from "@/layout/header/partials/Search.vue";
// import NotificationsMenu from "@/layout/header/partials/NotificationsMenu.vue";
import UserMenu from "@/layout/header/partials/UserMenu.vue";
import $ from "jquery";
import Delegations from "@/layout/header/partials/Delegations.vue";
import { removeTooltips } from "@/core/helpers/dom";

export default {
  name: "Topbar",
  data() {
    return {
      isFullScreenView: false,
    };
  },
  methods: {
    redirectDocumentPage() {
      removeTooltips();
      this.$router.push({ name: "documents" });
    },
    redirectNotificationPage() {
      this.$router.push({ name: "AccountNotifications" });
    },
    toggleFullScreen() {
      removeTooltips();
      if (!document.fullscreenElement) {
        this.isFullScreenView = true;
        document.documentElement.requestFullscreen();
      } else if (document.exitFullscreen) {
        this.isFullScreenView = false;
        document.exitFullscreen();
      }
    },
  },
  components: {
    Search,
    // NotificationsMenu,
    UserMenu,
    Delegations,
  },
};
</script>
