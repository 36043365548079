<template>
  <div
    class="header-menu align-items-stretch"
    data-sx-drawer="true"
    data-sx-drawer-name="header-menu"
    data-sx-drawer-activate="{default: true, lg: false}"
    data-sx-drawer-overlay="true"
    data-sx-drawer-width="{default:'200px', '300px': '250px'}"
    data-sx-drawer-direction="end"
    data-sx-drawer-toggle="#sx_header_menu_mobile_toggle"
    data-sx-place="true"
    data-sx-place-mode="prepend"
    data-sx-place-parent="{default: '#sx_body', lg: '#sx_header_nav'}"
  >
    <div
      class="menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold align-items-stretch header-menu-child"
      id="sx_header_menu"
      data-sx-menu="true"
    >
      <MobileTopbar v-if="$isMobile()" />
      <template v-for="(item, i) in HeaderMenuConfig" :key="i">
        <template v-if="!item.heading">
          <template v-for="(menuItem, j) in item.pages" :key="j">
            <div v-if="menuItem.heading" class="menu-item me-lg-1">
              <router-link
                class="menu-link"
                :to="menuItem.route"
                :class="{
                  'header-menu-item-active': hasActiveChildren(menuItem.route),
                }"
              >
                <span class="menu-icon"
                  ><i v-if="menuItem.fontIcon" :class="menuItem.fontIcon"></i
                ></span>
                <span class="menu-title">{{
                  $t(
                    menuItem.heading,
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</span>
              </router-link>
            </div>
          </template>
        </template>
        <div
          v-if="item.heading"
          :data-sx-menu-trigger="
            $isMobile() || innerWidth <= 1024 ? 'click' : 'hover'
          "
          data-sx-menu-placement="bottom-start"
          class="menu-item menu-lg-down-accordion me-lg-1"
        >
          <span class="menu-link">
            <span class="menu-title">{{
              $t(item.heading, {}, { locale: this.$store.state.activeLang })
            }}</span>
            <span class="menu-arrow d-lg-none"></span>
          </span>
          <div
            class="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-rounded-0 py-3 w-lg-225px border"
          >
            <template v-for="(menuItem, j) in item.pages" :key="j">
              <div
                v-if="menuItem.sectionTitle"
                data-sx-menu-trigger="{default:'click', lg: 'hover'}"
                data-sx-menu-placement="right-start"
                class="menu-item menu-lg-down-accordion"
              >
                <span
                  class="menu-link"
                  :class="{
                    'header-menu-item-active': hasActiveChildren(
                      menuItem.route
                    ),
                  }"
                >
                  <span class="menu-icon">
                    <i
                      v-if="headerMenuIcons === 'font'"
                      :class="menuItem.fontIcon"
                      class="bi fs-4"
                    ></i>
                    <span
                      v-if="headerMenuIcons === 'svg'"
                      class="svg-icon svg-icon-2"
                    >
                      <inline-svg :src="menuItem.svgIcon" />
                    </span>
                  </span>
                  <span class="menu-title">{{
                    translate(menuItem.sectionTitle)
                  }}</span>
                  <span class="menu-arrow"></span>
                </span>
                <div
                  class="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-active-bg py-3 w-lg-225px border"
                >
                  <template v-for="(menuItem1, k) in menuItem.sub" :key="k">
                    <div
                      v-if="menuItem1.sectionTitle"
                      data-sx-menu-trigger="{default:'click', lg: 'hover'}"
                      data-sx-menu-placement="right-start"
                      class="menu-item menu-lg-down-accordion"
                    >
                      <span
                        class="menu-link"
                        :class="{
                          'header-menu-item-active': hasActiveChildren(
                            menuItem1.route
                          ),
                        }"
                      >
                        <span class="menu-bullet">
                          <span class="bullet bullet-dot"></span>
                        </span>
                        <span class="menu-title">{{
                          translate(menuItem1.sectionTitle)
                        }}</span>
                        <span class="menu-arrow"></span>
                      </span>
                      <div
                        class="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-active-bg py-3 w-lg-225px border"
                      >
                        <template
                          v-for="(menuItem2, l) in menuItem1.sub"
                          :key="l"
                        >
                          <div class="menu-item">
                            <router-link
                              class="menu-link"
                              active-class="active"
                              :to="menuItem2.route"
                            >
                              <span class="menu-bullet">
                                <span class="bullet bullet-dot"></span>
                              </span>
                              <span class="menu-title">{{
                                translate(menuItem2.heading)
                              }}</span>
                            </router-link>
                          </div>
                        </template>
                      </div>
                    </div>
                    <div v-if="menuItem1.heading" class="menu-item">
                      <router-link
                        class="menu-link"
                        active-class="active"
                        :to="menuItem1.route"
                      >
                        <span class="menu-bullet">
                          <span class="bullet bullet-dot"></span>
                        </span>
                        <span class="menu-title">{{
                          translate(menuItem1.heading)
                        }}</span>
                      </router-link>
                    </div>
                  </template>
                </div>
              </div>
              <div v-if="menuItem.heading" class="menu-item">
                <router-link
                  class="menu-link"
                  active-class="active"
                  :to="menuItem.route"
                >
                  <span class="menu-icon">
                    <span class="svg-icon svg-icon-2">
                      <inline-svg
                        src="/extras/media/icons/duotune/layouts/lay009.svg"
                      />
                    </span>
                  </span>
                  <span class="menu-title">{{
                    translate(menuItem.heading)
                  }}</span>
                </router-link>
              </div>
            </template>
          </div>
        </div>
      </template>
      <template v-for="(menuItem, i) in visibleMenuItems" :key="i">
        <div
          v-if="!menuItem.isCustomObject && menuItem.menuChildItems.length == 0"
          class="menu-item me-lg-1 menu"
        >
          <a
            class="menu-link"
            :class="{
              'header-menu-item-active': hasActiveChildren(menuItem.url),
            }"
            :target="menuItem.isOpenNewTab ? '_blank' : '_self'"
            :href="String.isNullOrWhiteSpace(menuItem.url) ? '#' : menuItem.url"
          >
            <span v-if="menuItem.icon" class="menu-icon">
              <i
                :class="menuItem.icon"
                class="fs-4"
                :style="{ color: menuItem.iconColor }"
                v-if="menuItem.iconColor"
              ></i>
              <i :class="menuItem.icon" class="fs-4" v-else></i>
            </span>
            <span class="menu-title">{{ translate(menuItem) }}</span>
          </a>
        </div>
        <div
          :data-sx-menu-trigger="
            $isMobile() || innerWidth <= 1024 ? 'click' : 'hover'
          "
          data-sx-menu-placement="bottom-start"
          class="menu-item menu-lg-down-accordion me-lg-1"
          v-if="!menuItem.isCustomObject && menuItem.menuChildItems.length > 0"
        >
          <span
            class="menu-link"
            :class="{
              'header-menu-item-active': hasActiveChildren(
                '',
                menuItem.menuChildItems
              ),
            }"
          >
            <span v-if="menuItem.icon" class="menu-icon">
              <i
                :class="menuItem.icon"
                class="fs-4"
                :style="{ color: menuItem.iconColor }"
                v-if="menuItem.iconColor"
              ></i>
              <i :class="menuItem.icon" class="fs-4" v-else></i>
            </span>
            <span class="menu-title">{{ translate(menuItem) }}</span>
            <span class="menu-arrow"></span>
          </span>
          <div
            class="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-rounded-0 py-3 w-lg-225px border"
          >
            <template v-for="(item2, j) in menuItem.menuChildItems" :key="j">
              <a
                v-if="item2.menuChildItems.length == 0 && !item2.isCustomObject"
                class="menu-link"
                :href="String.isNullOrWhiteSpace(item2.url) ? '#' : item2.url"
                :target="item2.isOpenNewTab ? '_blank' : '_self'"
              >
                <span v-if="item2.icon" class="menu-icon">
                  <i
                    :class="item2.icon"
                    class="fs-4"
                    :style="{ color: item2.iconColor }"
                    v-if="item2.iconColor"
                  ></i>
                  <i :class="item2.icon" class="fs-4" v-else></i>
                </span>
                <span class="menu-title">{{ translate(item2) }}</span>
              </a>
              <div
                data-sx-menu-trigger="{default:'click', lg: 'hover'}"
                data-sx-menu-placement="right-start"
                class="menu-item menu-lg-down-accordion"
                v-else
              >
                <span class="menu-link">
                  <span v-if="item2.icon" class="menu-icon">
                    <i
                      :class="item2.icon"
                      class="fs-4"
                      :style="{ color: item2.iconColor }"
                      v-if="item2.iconColor"
                    ></i>
                    <i :class="item2.icon" class="fs-4" v-else></i>
                  </span>
                  <span class="menu-title">{{ translate(item2) }}</span>
                  <span class="menu-arrow"></span>
                </span>
                <div
                  class="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-active-bg py-3 w-lg-225px border"
                >
                  <template v-if="item2.isCustomObject">
                    <router-link
                      class="menu-link"
                      :to="{
                        name: 'new',
                        params: { key: item2.key },
                      }"
                      :target="item2.isOpenNewTab ? '_blank' : '_self'"
                    >
                      <span class="menu-title">
                        {{
                          $t(
                            "Set.New",
                            {},
                            { locale: this.$store.state.activeLang }
                          )
                        }}</span
                      >
                    </router-link>
                    <router-link
                      class="menu-link"
                      :to="{
                        name: 'list',
                        params: { key: item2.key },
                      }"
                      :target="item2.isOpenNewTab ? '_blank' : '_self'"
                    >
                      <span class="menu-title">
                        {{
                          $t(
                            "Set.List",
                            {},
                            { locale: this.$store.state.activeLang }
                          )
                        }}</span
                      >
                    </router-link>
                  </template>
                  <template v-for="(item3, j) in item2.menuChildItems" :key="j">
                    <a
                      v-if="
                        item3.menuChildItems.length == 0 &&
                        !item3.isCustomObject
                      "
                      class="menu-link"
                      :href="
                        String.isNullOrWhiteSpace(item3.url) ? '#' : item3.url
                      "
                      :target="item3.isOpenNewTab ? '_blank' : '_self'"
                    >
                      <span v-if="item3.icon" class="menu-icon">
                        <i
                          :class="item3.icon"
                          class="fs-4"
                          :style="{ color: item3.iconColor }"
                          v-if="item3.iconColor"
                        ></i>
                        <i :class="item3.icon" class="fs-4" v-else></i>
                      </span>
                      <span class="menu-title">{{ translate(item3) }}</span>
                    </a>
                    <div
                      data-sx-menu-trigger="{default:'click', lg: 'hover'}"
                      data-sx-menu-placement="left-start"
                      class="menu-item menu-lg-down-accordion"
                      v-else
                    >
                      <span class="menu-link">
                        <span v-if="item3.icon" class="menu-icon">
                          <i
                            :class="item3.icon"
                            class="fs-4"
                            :style="{ color: item3.iconColor }"
                            v-if="item3.iconColor"
                          ></i>
                          <i :class="item3.icon" class="fs-4" v-else></i>
                        </span>
                        <span class="menu-title">{{ translate(item3) }}</span>
                        <span class="menu-arrow"></span>
                      </span>
                      <div
                        class="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-active-bg py-3 w-lg-225px border"
                      >
                        <template v-if="item3.isCustomObject">
                          <router-link
                            class="menu-link"
                            :to="{
                              name: 'new',
                              params: { key: item3.key },
                            }"
                            :target="item3.isOpenNewTab ? '_blank' : '_self'"
                          >
                            <span class="menu-title">
                              {{
                                $t(
                                  "Set.New",
                                  {},
                                  {
                                    locale: this.$store.state.activeLang,
                                  }
                                )
                              }}</span
                            >
                          </router-link>
                          <router-link
                            class="menu-link"
                            :to="{
                              name: 'list',
                              params: { key: item3.key },
                            }"
                            :target="item3.isOpenNewTab ? '_blank' : '_self'"
                          >
                            <span class="menu-title">
                              {{
                                $t(
                                  "Set.List",
                                  {},
                                  {
                                    locale: this.$store.state.activeLang,
                                  }
                                )
                              }}</span
                            >
                          </router-link>
                        </template>
                        <template
                          v-for="(item4, j) in item3.menuChildItems"
                          :key="j"
                        >
                          <a
                            v-if="
                              item4.menuChildItems.length == 0 &&
                              !item4.isCustomObject
                            "
                            class="menu-link"
                            :href="
                              String.isNullOrWhiteSpace(item4.url)
                                ? '#'
                                : item4.url
                            "
                            :target="item4.isOpenNewTab ? '_blank' : '_self'"
                          >
                            <span v-if="item4.icon" class="menu-icon">
                              <i
                                :class="item4.icon"
                                class="fs-4"
                                :style="{ color: item4.iconColor }"
                                v-if="item4.iconColor"
                              ></i>
                              <i :class="item4.icon" class="fs-4" v-else></i>
                            </span>
                            <span class="menu-title">{{
                              translate(item4)
                            }}</span>
                          </a>
                          <div
                            data-sx-menu-trigger="{default:'click', lg: 'hover'}"
                            data-sx-menu-placement="right-start"
                            class="menu-item menu-lg-down-accordion"
                            v-else
                          >
                            <span class="menu-link">
                              <span v-if="item4.icon" class="menu-icon">
                                <i
                                  :class="item4.icon"
                                  class="fs-4"
                                  :style="{ color: item4.iconColor }"
                                  v-if="item4.iconColor"
                                ></i>
                                <i :class="item4.icon" class="fs-4" v-else></i>
                              </span>
                              <span class="menu-title">{{
                                translate(item4)
                              }}</span>
                              <span class="menu-arrow"></span>
                            </span>
                            <div
                              class="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-active-bg py-3 w-lg-225px border"
                            >
                              <template v-if="item4.isCustomObject">
                                <router-link
                                  class="menu-link"
                                  :to="{
                                    name: 'new',
                                    params: { key: item4.key },
                                  }"
                                  :target="
                                    item4.isOpenNewTab ? '_blank' : '_self'
                                  "
                                >
                                  <span class="menu-title">
                                    {{
                                      $t(
                                        "Set.New",
                                        {},
                                        {
                                          locale: this.$store.state.activeLang,
                                        }
                                      )
                                    }}</span
                                  >
                                </router-link>
                                <router-link
                                  class="menu-link"
                                  :to="{
                                    name: 'list',
                                    params: { key: item4.key },
                                  }"
                                  :target="
                                    item4.isOpenNewTab ? '_blank' : '_self'
                                  "
                                >
                                  <span class="menu-title">
                                    {{
                                      $t(
                                        "Set.List",
                                        {},
                                        {
                                          locale: this.$store.state.activeLang,
                                        }
                                      )
                                    }}</span
                                  >
                                </router-link>
                              </template>
                              <template
                                v-for="(item5, j) in item4.menuChildItems"
                                :key="j"
                              >
                                <a
                                  v-if="item5.menuChildItems.length == 0"
                                  class="menu-link"
                                  :href="
                                    String.isNullOrWhiteSpace(item5.url)
                                      ? '#'
                                      : item5.url
                                  "
                                  :target="
                                    item5.isOpenNewTab ? '_blank' : '_self'
                                  "
                                >
                                  <span v-if="item5.icon" class="menu-icon">
                                    <i
                                      :class="item5.icon"
                                      class="fs-4"
                                      :style="{
                                        color: item5.iconColor,
                                      }"
                                      v-if="item5.iconColor"
                                    ></i>
                                    <i
                                      :class="item5.icon"
                                      class="fs-4"
                                      v-else
                                    ></i>
                                  </span>
                                  <span class="menu-title">{{
                                    translate(item5)
                                  }}</span>
                                </a>
                              </template>
                            </div>
                          </div>
                        </template>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div
          :data-sx-menu-trigger="
            $isMobile() || innerWidth <= 1024 ? 'click' : 'hover'
          "
          data-sx-menu-placement="bottom-start"
          class="menu-item menu-lg-down-accordion me-lg-1"
          v-else-if="menuItem.isCustomObject"
        >
          <span
            class="menu-link"
            :class="{
              'header-menu-item-active': hasActiveChildren(
                menuItem.key,
                menuItem.menuChildItems
              ),
            }"
          >
            <span v-if="menuItem.icon" class="menu-icon">
              <i
                :class="menuItem.icon"
                class="fs-4"
                :style="{ color: menuItem.iconColor }"
                v-if="menuItem.iconColor"
              ></i>
              <i :class="menuItem.icon" class="fs-4" v-else></i>
            </span>
            <span class="menu-title">{{ translate(menuItem) }}</span>
            <span class="menu-arrow"></span>
          </span>
          <div
            class="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-rounded-0 py-3 w-lg-225px border"
          >
            <router-link
              class="menu-link"
              :to="{ name: 'new', params: { key: menuItem.key } }"
              :target="menuItem.isOpenNewTab ? '_blank' : '_self'"
            >
              <span class="menu-title">
                {{
                  $t("Set.New", {}, { locale: this.$store.state.activeLang })
                }}</span
              >
            </router-link>
            <router-link
              class="menu-link"
              :to="{ name: 'list', params: { key: menuItem.key } }"
              :target="menuItem.isOpenNewTab ? '_blank' : '_self'"
            >
              <span class="menu-title">
                {{
                  $t("Set.List", {}, { locale: this.$store.state.activeLang })
                }}</span
              >
            </router-link>
            <template v-for="(item2, j) in menuItem.menuChildItems" :key="j">
              <a
                v-if="item2.menuChildItems.length == 0 && !item2.isCustomObject"
                class="menu-link"
                :href="String.isNullOrWhiteSpace(item2.url) ? '#' : item2.url"
                :target="item2.isOpenNewTab ? '_blank' : '_self'"
              >
                <span v-if="item2.icon" class="menu-icon">
                  <i
                    :class="item2.icon"
                    class="fs-4"
                    :style="{ color: item2.iconColor }"
                    v-if="item2.iconColor"
                  ></i>
                  <i :class="item2.icon" class="fs-4" v-else></i>
                </span>
                <span class="menu-title">{{ translate(item2) }}</span>
              </a>
              <div
                data-sx-menu-trigger="{default:'click', lg: 'hover'}"
                data-sx-menu-placement="right-start"
                class="menu-item menu-lg-down-accordion"
                v-else
              >
                <span class="menu-link">
                  <span v-if="item2.icon" class="menu-icon">
                    <i
                      :class="item2.icon"
                      class="fs-4"
                      :style="{ color: item2.iconColor }"
                      v-if="item2.iconColor"
                    ></i>
                    <i :class="item2.icon" class="fs-4" v-else></i>
                  </span>
                  <span class="menu-title">{{ translate(item2) }}</span>
                  <span class="menu-arrow"></span>
                </span>
                <div
                  class="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-active-bg py-3 w-lg-225px border"
                >
                  <template v-if="item2.isCustomObject">
                    <router-link
                      class="menu-link"
                      :to="{
                        name: 'new',
                        params: { key: item2.key },
                      }"
                      :target="item2.isOpenNewTab ? '_blank' : '_self'"
                    >
                      <span class="menu-title">
                        {{
                          $t(
                            "Set.New",
                            {},
                            { locale: this.$store.state.activeLang }
                          )
                        }}</span
                      >
                    </router-link>
                    <router-link
                      class="menu-link"
                      :to="{
                        name: 'list',
                        params: { key: item2.key },
                      }"
                      :target="item2.isOpenNewTab ? '_blank' : '_self'"
                    >
                      <span class="menu-title">
                        {{
                          $t(
                            "Set.List",
                            {},
                            { locale: this.$store.state.activeLang }
                          )
                        }}</span
                      >
                    </router-link>
                  </template>
                  <template v-for="(item3, j) in item2.menuChildItems" :key="j">
                    <a
                      v-if="
                        item3.menuChildItems.length == 0 &&
                        !item3.isCustomObject
                      "
                      class="menu-link"
                      :href="
                        String.isNullOrWhiteSpace(item3.url) ? '#' : item3.url
                      "
                      :target="item3.isOpenNewTab ? '_blank' : '_self'"
                    >
                      <span v-if="item3.icon" class="menu-icon">
                        <i
                          :class="item3.icon"
                          class="fs-4"
                          :style="{ color: item3.iconColor }"
                          v-if="item3.iconColor"
                        ></i>
                        <i :class="item3.icon" class="fs-4" v-else></i>
                      </span>
                      <span class="menu-title">{{ translate(item3) }}</span>
                    </a>
                    <div
                      data-sx-menu-trigger="{default:'click', lg: 'hover'}"
                      data-sx-menu-placement="left-start"
                      class="menu-item menu-lg-down-accordion"
                      v-else
                    >
                      <span class="menu-link">
                        <span v-if="item3.icon" class="menu-icon">
                          <i
                            :class="item3.icon"
                            class="fs-4"
                            :style="{ color: item3.iconColor }"
                            v-if="item3.iconColor"
                          ></i>
                          <i :class="item3.icon" class="fs-4" v-else></i>
                        </span>
                        <span class="menu-title">{{ translate(item3) }}</span>
                        <span class="menu-arrow"></span>
                      </span>
                      <div
                        class="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-active-bg py-3 w-lg-225px border"
                      >
                        <template v-if="item3.isCustomObject">
                          <router-link
                            class="menu-link"
                            :to="{
                              name: 'new',
                              params: { key: item3.key },
                            }"
                            :target="item3.isOpenNewTab ? '_blank' : '_self'"
                          >
                            <span class="menu-title">
                              {{
                                $t(
                                  "Set.New",
                                  {},
                                  {
                                    locale: this.$store.state.activeLang,
                                  }
                                )
                              }}</span
                            >
                          </router-link>
                          <router-link
                            class="menu-link"
                            :to="{
                              name: 'list',
                              params: { key: item3.key },
                            }"
                            :target="item3.isOpenNewTab ? '_blank' : '_self'"
                          >
                            <span class="menu-title">
                              {{
                                $t(
                                  "Set.List",
                                  {},
                                  {
                                    locale: this.$store.state.activeLang,
                                  }
                                )
                              }}</span
                            >
                          </router-link>
                        </template>
                        <template
                          v-for="(item4, j) in item3.menuChildItems"
                          :key="j"
                        >
                          <a
                            v-if="
                              item4.menuChildItems.length == 0 &&
                              !item4.isCustomObject
                            "
                            class="menu-link"
                            :href="
                              String.isNullOrWhiteSpace(item4.url)
                                ? '#'
                                : item4.url
                            "
                            :target="item4.isOpenNewTab ? '_blank' : '_self'"
                          >
                            <span v-if="item4.icon" class="menu-icon">
                              <i
                                :class="item4.icon"
                                class="fs-4"
                                :style="{ color: item4.iconColor }"
                                v-if="item4.iconColor"
                              ></i>
                              <i :class="item4.icon" class="fs-4" v-else></i>
                            </span>
                            <span class="menu-title">{{
                              translate(item4)
                            }}</span>
                          </a>
                          <div
                            data-sx-menu-trigger="{default:'click', lg: 'hover'}"
                            data-sx-menu-placement="right-start"
                            class="menu-item menu-lg-down-accordion"
                            v-else
                          >
                            <span class="menu-link">
                              <span v-if="item4.icon" class="menu-icon">
                                <i
                                  :class="item4.icon"
                                  class="fs-4"
                                  :style="{ color: item4.iconColor }"
                                  v-if="item4.iconColor"
                                ></i>
                                <i :class="item4.icon" class="fs-4" v-else></i>
                              </span>
                              <span class="menu-title">{{
                                translate(item4)
                              }}</span>
                              <span class="menu-arrow"></span>
                            </span>
                            <div
                              class="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-active-bg py-3 w-lg-225px border"
                            >
                              <template v-if="item4.isCustomObject">
                                <router-link
                                  class="menu-link"
                                  :to="{
                                    name: 'new',
                                    params: { key: item4.key },
                                  }"
                                  :target="
                                    item4.isOpenNewTab ? '_blank' : '_self'
                                  "
                                >
                                  <span class="menu-title">
                                    {{
                                      $t(
                                        "Set.New",
                                        {},
                                        {
                                          locale: this.$store.state.activeLang,
                                        }
                                      )
                                    }}</span
                                  >
                                </router-link>
                                <router-link
                                  class="menu-link"
                                  :to="{
                                    name: 'list',
                                    params: { key: item4.key },
                                  }"
                                  :target="
                                    item4.isOpenNewTab ? '_blank' : '_self'
                                  "
                                >
                                  <span class="menu-title">
                                    {{
                                      $t(
                                        "Set.List",
                                        {},
                                        {
                                          locale: this.$store.state.activeLang,
                                        }
                                      )
                                    }}</span
                                  >
                                </router-link>
                              </template>
                              <template
                                v-for="(item5, j) in item4.menuChildItems"
                                :key="j"
                              >
                                <a
                                  v-if="item5.menuChildItems.length == 0"
                                  class="menu-link"
                                  :href="
                                    String.isNullOrWhiteSpace(item5.url)
                                      ? '#'
                                      : item5.url
                                  "
                                  :target="
                                    item5.isOpenNewTab ? '_blank' : '_self'
                                  "
                                >
                                  <span v-if="item5.icon" class="menu-icon">
                                    <i
                                      :class="item5.icon"
                                      class="fs-4"
                                      :style="{
                                        color: item5.iconColor,
                                      }"
                                      v-if="item5.iconColor"
                                    ></i>
                                    <i
                                      :class="item5.icon"
                                      class="fs-4"
                                      v-else
                                    ></i>
                                  </span>
                                  <span class="menu-title">{{
                                    translate(item5)
                                  }}</span>
                                </a>
                              </template>
                            </div>
                          </div>
                        </template>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </template>
          </div>
        </div>
      </template>
      <template v-if="hiddenMenuItems.length > 0">
        <div
          @mouseover="hiddenMenuDropdownOnHover"
          :data-sx-menu-trigger="
            $isMobile() || innerWidth <= 1024 ? 'click' : 'hover'
          "
          data-sx-menu-placement="right-start"
          class="menu-item menu-lg-down-accordion me-lg-1 hidden-menu-items"
        >
          <span class="menu-link">
            <span class="menu-title cursor-pointer"
              ><i class="fas fa-bars fa-2x"></i
            ></span>
            <span class="menu-arrow"></span>
          </span>
          <div
            class="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-rounded-0 w-lg-225px border"
          >
            <div
              class="bg-light border mb-1 p-2"
              v-if="hiddenMenuSearchBox.show"
            >
              <div class="col">
                <input
                  type="text"
                  class="form-control"
                  :id="hiddenMenuSearchBox.id"
                  :name="hiddenMenuSearchBox.id"
                  autocomplete="off"
                  :spellcheck="$isTextSpellCheck"
                  :placeholder="hiddenMenuSearchBox.placeholder"
                  @keyup="filterMenuItems()"
                  v-model="hiddenMenuSearchBox.query"
                />
              </div>
            </div>
            <div class="hidden-menu-items-scrollable-block">
              <template v-for="(menuItem, i) in hiddenMenuItems" :key="i">
                <div
                  v-if="
                    !menuItem.isCustomObject &&
                    menuItem.menuChildItems.length == 0
                  "
                  class="menu-item me-lg-1 menu"
                >
                  <a
                    class="menu-link hidden-menu-item-search-item"
                    :class="{
                      'header-menu-item-active': hasActiveChildren(
                        menuItem.url
                      ),
                    }"
                    :target="menuItem.isOpenNewTab ? '_blank' : '_self'"
                    :href="
                      String.isNullOrWhiteSpace(menuItem.url)
                        ? '#'
                        : menuItem.url
                    "
                  >
                    <span v-if="menuItem.icon" class="menu-icon">
                      <i
                        :class="menuItem.icon"
                        class="fs-4"
                        :style="{ color: menuItem.iconColor }"
                        v-if="menuItem.iconColor"
                      ></i>
                      <i :class="menuItem.icon" class="fs-4" v-else></i>
                    </span>
                    <span class="menu-title">{{ translate(menuItem) }}</span>
                  </a>
                </div>
                <div
                  :data-sx-menu-trigger="
                    $isMobile() || innerWidth <= 1024 ? 'click' : 'hover'
                  "
                  data-sx-menu-placement="right-start"
                  class="menu-item menu-lg-down-accordion me-lg-1"
                  v-if="
                    !menuItem.isCustomObject &&
                    menuItem.menuChildItems.length > 0
                  "
                >
                  <span
                    class="menu-link hidden-menu-item-search-item"
                    :class="{
                      'header-menu-item-active': hasActiveChildren(
                        '',
                        menuItem.menuChildItems
                      ),
                    }"
                  >
                    <span v-if="menuItem.icon" class="menu-icon">
                      <i
                        :class="menuItem.icon"
                        class="fs-4"
                        :style="{ color: menuItem.iconColor }"
                        v-if="menuItem.iconColor"
                      ></i>
                      <i :class="menuItem.icon" class="fs-4" v-else></i>
                    </span>
                    <span class="menu-title">{{ translate(menuItem) }}</span>
                    <span class="menu-arrow"></span>
                  </span>
                  <div
                    class="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-rounded-0 py-3 w-lg-225px border"
                  >
                    <template
                      v-for="(item2, j) in menuItem.menuChildItems"
                      :key="j"
                    >
                      <a
                        v-if="
                          item2.menuChildItems.length == 0 &&
                          !item2.isCustomObject
                        "
                        class="menu-link"
                        :href="
                          String.isNullOrWhiteSpace(item2.url) ? '#' : item2.url
                        "
                        :target="item2.isOpenNewTab ? '_blank' : '_self'"
                      >
                        <span v-if="item2.icon" class="menu-icon">
                          <i
                            :class="item2.icon"
                            class="fs-4"
                            :style="{ color: item2.iconColor }"
                            v-if="item2.iconColor"
                          ></i>
                          <i :class="item2.icon" class="fs-4" v-else></i>
                        </span>
                        <span class="menu-title">{{ translate(item2) }}</span>
                      </a>
                      <div
                        data-sx-menu-trigger="{default:'click', lg: 'hover'}"
                        data-sx-menu-placement="right-start"
                        class="menu-item menu-lg-down-accordion"
                        v-else
                      >
                        <span class="menu-link">
                          <span v-if="item2.icon" class="menu-icon">
                            <i
                              :class="item2.icon"
                              class="fs-4"
                              :style="{ color: item2.iconColor }"
                              v-if="item2.iconColor"
                            ></i>
                            <i :class="item2.icon" class="fs-4" v-else></i>
                          </span>
                          <span class="menu-title">{{ translate(item2) }}</span>
                          <span class="menu-arrow"></span>
                        </span>
                        <div
                          class="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-active-bg py-3 w-lg-225px border"
                        >
                          <template v-if="item2.isCustomObject">
                            <router-link
                              class="menu-link"
                              :to="{
                                name: 'new',
                                params: { key: item2.key },
                              }"
                              :target="item2.isOpenNewTab ? '_blank' : '_self'"
                            >
                              <span class="menu-title">
                                {{
                                  $t(
                                    "Set.New",
                                    {},
                                    { locale: this.$store.state.activeLang }
                                  )
                                }}</span
                              >
                            </router-link>
                            <router-link
                              class="menu-link"
                              :to="{
                                name: 'list',
                                params: { key: item2.key },
                              }"
                              :target="item2.isOpenNewTab ? '_blank' : '_self'"
                            >
                              <span class="menu-title">
                                {{
                                  $t(
                                    "Set.List",
                                    {},
                                    { locale: this.$store.state.activeLang }
                                  )
                                }}</span
                              >
                            </router-link>
                          </template>
                          <template
                            v-for="(item3, j) in item2.menuChildItems"
                            :key="j"
                          >
                            <a
                              v-if="
                                item3.menuChildItems.length == 0 &&
                                !item3.isCustomObject
                              "
                              class="menu-link"
                              :href="
                                String.isNullOrWhiteSpace(item3.url)
                                  ? '#'
                                  : item3.url
                              "
                              :target="item3.isOpenNewTab ? '_blank' : '_self'"
                            >
                              <span v-if="item3.icon" class="menu-icon">
                                <i
                                  :class="item3.icon"
                                  class="fs-4"
                                  :style="{ color: item3.iconColor }"
                                  v-if="item3.iconColor"
                                ></i>
                                <i :class="item3.icon" class="fs-4" v-else></i>
                              </span>
                              <span class="menu-title">{{
                                translate(item3)
                              }}</span>
                            </a>
                            <div
                              data-sx-menu-trigger="{default:'click', lg: 'hover'}"
                              data-sx-menu-placement="left-start"
                              class="menu-item menu-lg-down-accordion"
                              v-else
                            >
                              <span class="menu-link">
                                <span v-if="item3.icon" class="menu-icon">
                                  <i
                                    :class="item3.icon"
                                    class="fs-4"
                                    :style="{ color: item3.iconColor }"
                                    v-if="item3.iconColor"
                                  ></i>
                                  <i
                                    :class="item3.icon"
                                    class="fs-4"
                                    v-else
                                  ></i>
                                </span>
                                <span class="menu-title">{{
                                  translate(item3)
                                }}</span>
                                <span class="menu-arrow"></span>
                              </span>
                              <div
                                class="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-active-bg py-3 w-lg-225px border"
                              >
                                <template v-if="item3.isCustomObject">
                                  <router-link
                                    class="menu-link"
                                    :to="{
                                      name: 'new',
                                      params: { key: item3.key },
                                    }"
                                    :target="
                                      item3.isOpenNewTab ? '_blank' : '_self'
                                    "
                                  >
                                    <span class="menu-title">
                                      {{
                                        $t(
                                          "Set.New",
                                          {},
                                          {
                                            locale:
                                              this.$store.state.activeLang,
                                          }
                                        )
                                      }}</span
                                    >
                                  </router-link>
                                  <router-link
                                    class="menu-link"
                                    :to="{
                                      name: 'list',
                                      params: { key: item3.key },
                                    }"
                                    :target="
                                      item3.isOpenNewTab ? '_blank' : '_self'
                                    "
                                  >
                                    <span class="menu-title">
                                      {{
                                        $t(
                                          "Set.List",
                                          {},
                                          {
                                            locale:
                                              this.$store.state.activeLang,
                                          }
                                        )
                                      }}</span
                                    >
                                  </router-link>
                                </template>
                                <template
                                  v-for="(item4, j) in item3.menuChildItems"
                                  :key="j"
                                >
                                  <a
                                    v-if="
                                      item4.menuChildItems.length == 0 &&
                                      !item4.isCustomObject
                                    "
                                    class="menu-link"
                                    :href="
                                      String.isNullOrWhiteSpace(item4.url)
                                        ? '#'
                                        : item4.url
                                    "
                                    :target="
                                      item4.isOpenNewTab ? '_blank' : '_self'
                                    "
                                  >
                                    <span v-if="item4.icon" class="menu-icon">
                                      <i
                                        :class="item4.icon"
                                        class="fs-4"
                                        :style="{ color: item4.iconColor }"
                                        v-if="item4.iconColor"
                                      ></i>
                                      <i
                                        :class="item4.icon"
                                        class="fs-4"
                                        v-else
                                      ></i>
                                    </span>
                                    <span class="menu-title">{{
                                      translate(item4)
                                    }}</span>
                                  </a>
                                  <div
                                    data-sx-menu-trigger="{default:'click', lg: 'hover'}"
                                    data-sx-menu-placement="right-start"
                                    class="menu-item menu-lg-down-accordion"
                                    v-else
                                  >
                                    <span class="menu-link">
                                      <span v-if="item4.icon" class="menu-icon">
                                        <i
                                          :class="item4.icon"
                                          class="fs-4"
                                          :style="{ color: item4.iconColor }"
                                          v-if="item4.iconColor"
                                        ></i>
                                        <i
                                          :class="item4.icon"
                                          class="fs-4"
                                          v-else
                                        ></i>
                                      </span>
                                      <span class="menu-title">{{
                                        translate(item4)
                                      }}</span>
                                      <span class="menu-arrow"></span>
                                    </span>
                                    <div
                                      class="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-active-bg py-3 w-lg-225px border"
                                    >
                                      <template v-if="item4.isCustomObject">
                                        <router-link
                                          class="menu-link"
                                          :to="{
                                            name: 'new',
                                            params: { key: item4.key },
                                          }"
                                          :target="
                                            item4.isOpenNewTab
                                              ? '_blank'
                                              : '_self'
                                          "
                                        >
                                          <span class="menu-title">
                                            {{
                                              $t(
                                                "Set.New",
                                                {},
                                                {
                                                  locale:
                                                    this.$store.state
                                                      .activeLang,
                                                }
                                              )
                                            }}</span
                                          >
                                        </router-link>
                                        <router-link
                                          class="menu-link"
                                          :to="{
                                            name: 'list',
                                            params: { key: item4.key },
                                          }"
                                          :target="
                                            item4.isOpenNewTab
                                              ? '_blank'
                                              : '_self'
                                          "
                                        >
                                          <span class="menu-title">
                                            {{
                                              $t(
                                                "Set.List",
                                                {},
                                                {
                                                  locale:
                                                    this.$store.state
                                                      .activeLang,
                                                }
                                              )
                                            }}</span
                                          >
                                        </router-link>
                                      </template>
                                      <template
                                        v-for="(
                                          item5, j
                                        ) in item4.menuChildItems"
                                        :key="j"
                                      >
                                        <a
                                          v-if="
                                            item5.menuChildItems.length == 0
                                          "
                                          class="menu-link"
                                          :href="
                                            String.isNullOrWhiteSpace(item5.url)
                                              ? '#'
                                              : item5.url
                                          "
                                          :target="
                                            item5.isOpenNewTab
                                              ? '_blank'
                                              : '_self'
                                          "
                                        >
                                          <span
                                            v-if="item5.icon"
                                            class="menu-icon"
                                          >
                                            <i
                                              :class="item5.icon"
                                              class="fs-4"
                                              :style="{
                                                color: item5.iconColor,
                                              }"
                                              v-if="item5.iconColor"
                                            ></i>
                                            <i
                                              :class="item5.icon"
                                              class="fs-4"
                                              v-else
                                            ></i>
                                          </span>
                                          <span class="menu-title">{{
                                            translate(item5)
                                          }}</span>
                                        </a>
                                      </template>
                                    </div>
                                  </div>
                                </template>
                              </div>
                            </div>
                          </template>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
                <div
                  :data-sx-menu-trigger="
                    $isMobile() || innerWidth <= 1024 ? 'click' : 'hover'
                  "
                  data-sx-menu-placement="right-start"
                  class="menu-item menu-lg-down-accordion me-lg-1"
                  v-else-if="menuItem.isCustomObject"
                >
                  <span
                    class="menu-link hidden-menu-item-search-item"
                    :class="{
                      'header-menu-item-active': hasActiveChildren(
                        menuItem.key,
                        menuItem.menuChildItems
                      ),
                    }"
                  >
                    <span v-if="menuItem.icon" class="menu-icon">
                      <i
                        :class="menuItem.icon"
                        class="fs-4"
                        :style="{ color: menuItem.iconColor }"
                        v-if="menuItem.iconColor"
                      ></i>
                      <i :class="menuItem.icon" class="fs-4" v-else></i>
                    </span>
                    <span class="menu-title">{{ translate(menuItem) }}</span>
                    <span class="menu-arrow"></span>
                  </span>
                  <div
                    class="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-rounded-0 py-3 w-lg-225px border"
                  >
                    <router-link
                      class="menu-link"
                      :to="{ name: 'new', params: { key: menuItem.key } }"
                      :target="menuItem.isOpenNewTab ? '_blank' : '_self'"
                    >
                      <span class="menu-title">
                        {{
                          $t(
                            "Set.New",
                            {},
                            { locale: this.$store.state.activeLang }
                          )
                        }}</span
                      >
                    </router-link>
                    <router-link
                      class="menu-link"
                      :to="{ name: 'list', params: { key: menuItem.key } }"
                      :target="menuItem.isOpenNewTab ? '_blank' : '_self'"
                    >
                      <span class="menu-title">
                        {{
                          $t(
                            "Set.List",
                            {},
                            { locale: this.$store.state.activeLang }
                          )
                        }}</span
                      >
                    </router-link>
                    <template
                      v-for="(item2, j) in menuItem.menuChildItems"
                      :key="j"
                    >
                      <a
                        v-if="
                          item2.menuChildItems.length == 0 &&
                          !item2.isCustomObject
                        "
                        class="menu-link"
                        :href="
                          String.isNullOrWhiteSpace(item2.url) ? '#' : item2.url
                        "
                        :target="item2.isOpenNewTab ? '_blank' : '_self'"
                      >
                        <span v-if="item2.icon" class="menu-icon">
                          <i
                            :class="item2.icon"
                            class="fs-4"
                            :style="{ color: item2.iconColor }"
                            v-if="item2.iconColor"
                          ></i>
                          <i :class="item2.icon" class="fs-4" v-else></i>
                        </span>
                        <span class="menu-title">{{ translate(item2) }}</span>
                      </a>
                      <div
                        data-sx-menu-trigger="{default:'click', lg: 'hover'}"
                        data-sx-menu-placement="right-start"
                        class="menu-item menu-lg-down-accordion"
                        v-else
                      >
                        <span class="menu-link">
                          <span v-if="item2.icon" class="menu-icon">
                            <i
                              :class="item2.icon"
                              class="fs-4"
                              :style="{ color: item2.iconColor }"
                              v-if="item2.iconColor"
                            ></i>
                            <i :class="item2.icon" class="fs-4" v-else></i>
                          </span>
                          <span class="menu-title">{{ translate(item2) }}</span>
                          <span class="menu-arrow"></span>
                        </span>
                        <div
                          class="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-active-bg py-3 w-lg-225px border"
                        >
                          <template v-if="item2.isCustomObject">
                            <router-link
                              class="menu-link"
                              :to="{
                                name: 'new',
                                params: { key: item2.key },
                              }"
                              :target="item2.isOpenNewTab ? '_blank' : '_self'"
                            >
                              <span class="menu-title">
                                {{
                                  $t(
                                    "Set.New",
                                    {},
                                    { locale: this.$store.state.activeLang }
                                  )
                                }}</span
                              >
                            </router-link>
                            <router-link
                              class="menu-link"
                              :to="{
                                name: 'list',
                                params: { key: item2.key },
                              }"
                              :target="item2.isOpenNewTab ? '_blank' : '_self'"
                            >
                              <span class="menu-title">
                                {{
                                  $t(
                                    "Set.List",
                                    {},
                                    { locale: this.$store.state.activeLang }
                                  )
                                }}</span
                              >
                            </router-link>
                          </template>
                          <template
                            v-for="(item3, j) in item2.menuChildItems"
                            :key="j"
                          >
                            <a
                              v-if="
                                item3.menuChildItems.length == 0 &&
                                !item3.isCustomObject
                              "
                              class="menu-link"
                              :href="
                                String.isNullOrWhiteSpace(item3.url)
                                  ? '#'
                                  : item3.url
                              "
                              :target="item3.isOpenNewTab ? '_blank' : '_self'"
                            >
                              <span v-if="item3.icon" class="menu-icon">
                                <i
                                  :class="item3.icon"
                                  class="fs-4"
                                  :style="{ color: item3.iconColor }"
                                  v-if="item3.iconColor"
                                ></i>
                                <i :class="item3.icon" class="fs-4" v-else></i>
                              </span>
                              <span class="menu-title">{{
                                translate(item3)
                              }}</span>
                            </a>
                            <div
                              data-sx-menu-trigger="{default:'click', lg: 'hover'}"
                              data-sx-menu-placement="left-start"
                              class="menu-item menu-lg-down-accordion"
                              v-else
                            >
                              <span class="menu-link">
                                <span v-if="item3.icon" class="menu-icon">
                                  <i
                                    :class="item3.icon"
                                    class="fs-4"
                                    :style="{ color: item3.iconColor }"
                                    v-if="item3.iconColor"
                                  ></i>
                                  <i
                                    :class="item3.icon"
                                    class="fs-4"
                                    v-else
                                  ></i>
                                </span>
                                <span class="menu-title">{{
                                  translate(item3)
                                }}</span>
                                <span class="menu-arrow"></span>
                              </span>
                              <div
                                class="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-active-bg py-3 w-lg-225px border"
                              >
                                <template v-if="item3.isCustomObject">
                                  <router-link
                                    class="menu-link"
                                    :to="{
                                      name: 'new',
                                      params: { key: item3.key },
                                    }"
                                    :target="
                                      item3.isOpenNewTab ? '_blank' : '_self'
                                    "
                                  >
                                    <span class="menu-title">
                                      {{
                                        $t(
                                          "Set.New",
                                          {},
                                          {
                                            locale:
                                              this.$store.state.activeLang,
                                          }
                                        )
                                      }}</span
                                    >
                                  </router-link>
                                  <router-link
                                    class="menu-link"
                                    :to="{
                                      name: 'list',
                                      params: { key: item3.key },
                                    }"
                                    :target="
                                      item3.isOpenNewTab ? '_blank' : '_self'
                                    "
                                  >
                                    <span class="menu-title">
                                      {{
                                        $t(
                                          "Set.List",
                                          {},
                                          {
                                            locale:
                                              this.$store.state.activeLang,
                                          }
                                        )
                                      }}</span
                                    >
                                  </router-link>
                                </template>
                                <template
                                  v-for="(item4, j) in item3.menuChildItems"
                                  :key="j"
                                >
                                  <a
                                    v-if="
                                      item4.menuChildItems.length == 0 &&
                                      !item4.isCustomObject
                                    "
                                    class="menu-link"
                                    :href="
                                      String.isNullOrWhiteSpace(item4.url)
                                        ? '#'
                                        : item4.url
                                    "
                                    :target="
                                      item4.isOpenNewTab ? '_blank' : '_self'
                                    "
                                  >
                                    <span v-if="item4.icon" class="menu-icon">
                                      <i
                                        :class="item4.icon"
                                        class="fs-4"
                                        :style="{ color: item4.iconColor }"
                                        v-if="item4.iconColor"
                                      ></i>
                                      <i
                                        :class="item4.icon"
                                        class="fs-4"
                                        v-else
                                      ></i>
                                    </span>
                                    <span class="menu-title">{{
                                      translate(item4)
                                    }}</span>
                                  </a>
                                  <div
                                    data-sx-menu-trigger="{default:'click', lg: 'hover'}"
                                    data-sx-menu-placement="right-start"
                                    class="menu-item menu-lg-down-accordion"
                                    v-else
                                  >
                                    <span class="menu-link">
                                      <span v-if="item4.icon" class="menu-icon">
                                        <i
                                          :class="item4.icon"
                                          class="fs-4"
                                          :style="{ color: item4.iconColor }"
                                          v-if="item4.iconColor"
                                        ></i>
                                        <i
                                          :class="item4.icon"
                                          class="fs-4"
                                          v-else
                                        ></i>
                                      </span>
                                      <span class="menu-title">{{
                                        translate(item4)
                                      }}</span>
                                      <span class="menu-arrow"></span>
                                    </span>
                                    <div
                                      class="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-active-bg py-3 w-lg-225px border"
                                    >
                                      <template v-if="item4.isCustomObject">
                                        <router-link
                                          class="menu-link"
                                          :to="{
                                            name: 'new',
                                            params: { key: item4.key },
                                          }"
                                          :target="
                                            item4.isOpenNewTab
                                              ? '_blank'
                                              : '_self'
                                          "
                                        >
                                          <span class="menu-title">
                                            {{
                                              $t(
                                                "Set.New",
                                                {},
                                                {
                                                  locale:
                                                    this.$store.state
                                                      .activeLang,
                                                }
                                              )
                                            }}</span
                                          >
                                        </router-link>
                                        <router-link
                                          class="menu-link"
                                          :to="{
                                            name: 'list',
                                            params: { key: item4.key },
                                          }"
                                          :target="
                                            item4.isOpenNewTab
                                              ? '_blank'
                                              : '_self'
                                          "
                                        >
                                          <span class="menu-title">
                                            {{
                                              $t(
                                                "Set.List",
                                                {},
                                                {
                                                  locale:
                                                    this.$store.state
                                                      .activeLang,
                                                }
                                              )
                                            }}</span
                                          >
                                        </router-link>
                                      </template>
                                      <template
                                        v-for="(
                                          item5, j
                                        ) in item4.menuChildItems"
                                        :key="j"
                                      >
                                        <a
                                          v-if="
                                            item5.menuChildItems.length == 0
                                          "
                                          class="menu-link"
                                          :href="
                                            String.isNullOrWhiteSpace(item5.url)
                                              ? '#'
                                              : item5.url
                                          "
                                          :target="
                                            item5.isOpenNewTab
                                              ? '_blank'
                                              : '_self'
                                          "
                                        >
                                          <span
                                            v-if="item5.icon"
                                            class="menu-icon"
                                          >
                                            <i
                                              :class="item5.icon"
                                              class="fs-4"
                                              :style="{
                                                color: item5.iconColor,
                                              }"
                                              v-if="item5.iconColor"
                                            ></i>
                                            <i
                                              :class="item5.icon"
                                              class="fs-4"
                                              v-else
                                            ></i>
                                          </span>
                                          <span class="menu-title">{{
                                            translate(item5)
                                          }}</span>
                                        </a>
                                      </template>
                                    </div>
                                  </div>
                                </template>
                              </div>
                            </div>
                          </template>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n/index";
import HeaderMenuConfig from "@/core/config/HeaderMenuConfig";
import { headerMenuIcons } from "@/core/helpers/config";
import MobileTopbar from "@/layout/header/MobileTopbar.vue";
export default {
  name: "Menu",
  components: {
    MobileTopbar,
  },
  data() {
    return {
      innerWidth: window.innerWidth,
      visibleMenuItems: [],
      hiddenMenuItems: [],
      hiddenMenuSearchBox: {
        id: "sx-hidden-menu-item-search-box",
        placeholder: this.$t(
          "BaseModelFields.Search",
          {},
          { locale: this.$store.state.activeLang }
        ),
        show: false,
        query: "",
        showLength: 5,
      },
    };
  },
  watch: {
    "$store.state.userData.informations": {
      immediate: true,
      handler() {
        var userInfo = this.$store.getters.userInfo;

        if (!userInfo) return;

        var activeMenuItems = this.$store.getters.userInfo.activeMenuItems;

        this.visibleMenuItems = activeMenuItems
          .filter((f) => !f.isSideBarItem)
          .sort(function (a, b) {
            return a.displayOrder - b.displayOrder;
          });

        setTimeout(() => {
          if (this.$isMobile() || this.innerWidth < 1024) return;
          this.responsiveMenuConfigurations();
        }, 103);
      },
    },
  },
  methods: {
    hiddenMenuDropdownOnHover() {
      if (!this.hiddenMenuSearchBox.show) return;

      $(`#${this.hiddenMenuSearchBox.id}`).focus();
    },
    filterMenuItems() {
      //only parent items
      var searchText = String.searchTextReplace(
        this.hiddenMenuSearchBox.query.toLowerCase()
      );
      $(".hidden-menu-item-search-item").filter(function () {
        var parentDiv = $(this);
        parentDiv.toggle(
          String.searchTextReplace($(this).find(".menu-title").text().trim())
            .toLowerCase()
            .indexOf(searchText) > -1
        );
      });
    },
    responsiveMenuConfigurations() {
      var body = $(window),
        headerMenu = $("#sx_header_menu"),
        aside = $("#sx_aside"),
        navbarHeader = $(".navbar-header"),
        navbarParentIsHidden = navbarHeader
          .parent(".d-flex")
          .hasClass("d-lg-none"),
        topBar = $(".topbar"),
        asideWidth = this.$root.getElementWidth(aside),
        topBarWidth = this.$root.getElementWidth(topBar),
        backValue = this.$store.getters._isSideBarActive ? 200 : 300,
        removeWidth =
          (navbarParentIsHidden
            ? 0
            : this.$root.getElementWidth(navbarHeader)) +
          topBarWidth +
          asideWidth,
        headerMenuWidth =
          this.$root.getElementWidth(body) - removeWidth - backValue;

      if (!headerMenu) return;

      //set preview
      headerMenu.css({
        width: headerMenuWidth,
        overflow: "hidden",
      });

      //menuItemPadding: 0.25rem => 4px
      var self = this,
        width = 0,
        hiddenMenuItemStartValue = 0,
        menuItemPadding = 4;

      headerMenu.find(".menu-item.me-lg-1").each(function (i, menuItem) {
        width += self.$root.getElementWidth($(menuItem)) + menuItemPadding;
        if (width >= headerMenuWidth) {
          hiddenMenuItemStartValue = i;
          return false;
        }
      });

      var allMenuItems = this.visibleMenuItems;
      this.visibleMenuItems = allMenuItems.splice(
        0,
        hiddenMenuItemStartValue == 0
          ? this.visibleMenuItems.length
          : hiddenMenuItemStartValue
      );
      this.hiddenMenuItems = allMenuItems;

      this.hiddenMenuSearchBox.show =
        this.hiddenMenuItems.length >= this.hiddenMenuSearchBox.showLength;

      //remove preview
      headerMenu.removeAttr("style");
    },
  },
  setup() {
    const { t, te } = useI18n();
    const route = useRoute();
    const store = useStore();

    const hasActiveChildren = (itemRoute, parentItems) => {
      var currentRoute = route.path;
      if (route.meta.isSetPage) {
        var splitCurrentRoute = currentRoute.split("/");
        currentRoute = splitCurrentRoute[3]; //co key
      }

      if (String.isNullOrWhiteSpace(itemRoute)) {
        if (!String.isNullOrWhiteSpace(parentItems)) {
          var keys = parentItems.map(function (m) {
            if (
              !String.isNullOrWhiteSpace(m) &&
              !String.isNullOrWhiteSpace(m.url)
            )
              return m.url.split("/").pop();
          });

          return keys.includes(currentRoute);
        }
      }

      return itemRoute && itemRoute.includes(currentRoute);
    };

    const translate = (menuItem) => {
      if (!menuItem) return "";

      var isParentMenuItem = String.isNullOrWhiteSpace(
        menuItem.parentMenuItemPublicId
      );
      if (
        !String.isNullOrWhiteSpace(menuItem.formulaName) &&
        store.state.isMultiLanguage
      ) {
        var localizationValue = store.getters.getLocalizationValuesByParameters(
          {
            parentId: isParentMenuItem
              ? menuItem.menuPublicId
              : menuItem.parentMenuItemPublicId,
            itemFormulaName: menuItem.formulaName,
            itemTypeId: 7,
          }
        );
        if (localizationValue) {
          return localizationValue.value;
        }
      }

      return menuItem.name;
    };

    return {
      hasActiveChildren,
      headerMenuIcons,
      HeaderMenuConfig,
      translate,
    };
  },
};
</script>
